import logo from './images/Oakenfull Ventures-logos.jpeg';
import './App.css';

function App() {
  return (
    <div className="image-wrapper">
        <img src={logo} alt="Oakenfull Ventures" />
    </div>
  );
}

export default App;
